import { Radio } from '@mui/material';
import { useEffect, useState } from 'react';
import { essaySubTypeApplicationEssay } from '../../constants/staticData';
import ApplicationEssayStepper from './ApplicationEssayStepper';
import { applicationTagsForEssays } from '../../constants/other';
import { getOverwrittenData } from '../../utils/helper';

const AddApplicationEssay = ({
  selectedRadio = '',
  setSelectedRadio = () => {},
  addProcessStep = 0,
  setAddProcessStep = () => {},
  coreEssays = [],
  collaborators = [],
  commonEssays = [],
  isAdding = false,
  selectedCollaborators = [],
  essayData,
  setEssayData = () => {},
  selectTemplate,
  setSelectTemplate = () => {},
  copyOption = 'coreEssay',
  setCopyOption = () => {},
  collegeList = [],
  setDisableActionBtn = () => {},
}) => {
  const [selectedListItem, setSelectedListItem] = useState();
  const [essayList, setEssayList] = useState([]);
  const [collegeListData, setCollegeListData] = useState([]);

  useEffect(() => {
    if (
      selectedRadio === essaySubTypeApplicationEssay.college &&
      selectedListItem?.length > 0
    ) {
      setEssayList(selectedListItem[0]?.essays);
    } else {
      setEssayList(
        commonEssays?.filter(
          (essay) => Number(essay.submittedTo) === Number(selectedListItem)
        )
      );
    }
    setCollegeListData(
      collegeList?.sort((a, b) =>
        a?.collegeData?.collegeName.localeCompare(b?.collegeData?.collegeName)
      )
    );
    // eslint-disable-next-line
  }, [selectedListItem, collegeList, selectedRadio]);

  return (
    <>
      {!addProcessStep && (
        <div className="flex flex-col gap-4">
          <div className="text-lead-500 text-xs">
            Select a College OR an Application Platform for which you want to
            write an Essay.
          </div>
          <div className="flex gap-4">
            <div
              className={`${
                selectedRadio === essaySubTypeApplicationEssay.college
                  ? 'bg-brand-blue-500/10 border-brand-blue-500'
                  : 'border-lead-900/23'
              } flex px-4 py-6 gap-3 flex-1 border rounded-lg cursor-pointer hover:bg-brand-blue-500/10`}
              onClick={() =>
                setSelectedRadio(essaySubTypeApplicationEssay.college)
              }
            >
              <div>
                <Radio
                  checked={
                    selectedRadio === essaySubTypeApplicationEssay.college
                  }
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'Select College' }}
                  sx={{
                    padding: 0,
                  }}
                  onChange={() =>
                    setSelectedRadio(essaySubTypeApplicationEssay.college)
                  }
                />
              </div>
              <div className="flex flex-col gap-2">
                <div className="font-medium">Select College</div>
                <div className="text-xs text-grey-500">
                  Write an Essay for your preferred College.
                </div>
              </div>
            </div>
            <div
              className={`${
                selectedRadio === 'application'
                  ? 'bg-brand-blue-500/10 border-brand-blue-500'
                  : 'border-lead-900/23'
              } flex px-4 py-6 gap-3 flex-1 border rounded-lg cursor-pointer hover:bg-brand-blue-500/10`}
              onClick={() => setSelectedRadio('application')}
            >
              <div>
                <Radio
                  checked={selectedRadio === 'application'}
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'Select College' }}
                  sx={{
                    padding: 0,
                  }}
                  onChange={() => setSelectedRadio('application')}
                />
              </div>
              <div className="flex flex-col gap-2">
                <div className="font-medium">Select Application Platform</div>
                <div className="text-xs text-grey-500">
                  Write a Common Essay for Application Platforms like Common
                  App, UC, Coalition App etc.
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {addProcessStep && selectedRadio ? (
        <ApplicationEssayStepper
          activeStep={addProcessStep}
          setAddProcessStep={setAddProcessStep}
          selectedRadio={selectedRadio}
          selectedListItem={selectedListItem}
          setSelectedListItem={setSelectedListItem}
          essayList={getOverwrittenData(essayList)}
          selectItemsLabel={
            selectedRadio === essaySubTypeApplicationEssay.college
              ? 'College Name'
              : 'Select Application Platform'
          }
          itemList={
            selectedRadio === essaySubTypeApplicationEssay.college
              ? collegeListData
              : applicationTagsForEssays
          }
          collaborators={collaborators}
          isAdding={isAdding}
          coreEssays={coreEssays}
          essayData={essayData}
          setEssayData={setEssayData}
          selectTemplate={selectTemplate}
          setSelectTemplate={setSelectTemplate}
          copyOption={copyOption}
          setCopyOption={setCopyOption}
          setDisableActionBtn={setDisableActionBtn}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AddApplicationEssay;
