import { Add } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  Radio,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect, useState } from 'react';
import {
  essaySubTypeApplicationEssay,
  essayTypeForPlatform,
} from '../../constants/staticData';
import SelectOption from '../shared/SelectOption';
import SelectTeamMembers from '../shared/SelectTeamMembers';
import { applicationTags, essayCategory } from '../../constants/other';
import Tooltip from '../shared/Tooltip';
import useEssayStore from '../../stores/EssayStore';
import { checkEssayAdded } from '../../utils/helper';
import { CollegeAutoComplete } from '../myApplications/CollegeAutocomplete';
import TeamMembersGroup from '../shared/TeamMembersGroup';
import {
  filterUniAndTeamUniversily,
  UTILITY_TYPES,
} from '../../utils/utlityTypes';
import { openSnackbar } from '../shared/GlobalSnackbar/GlobalSnackbar';
import useAccessCheck from '../../utils/accessCheck';

const ApplicationEssayStepper = ({
  activeStep = '',
  selectItemsLabel = '',
  itemList = [],
  processType = '',
  selectedRadio = '',
  selectedListItem = {},
  setAddProcessStep = () => {},
  setSelectedListItem = () => {},
  essayList = [],
  coreEssays = [],
  collaborators = [],
  selectedCollaborators = [],
  isAdding = false,
  essayData,
  setEssayData = () => {},
  selectTemplate,
  setSelectTemplate = () => {},
  copyOption = '',
  setCopyOption = () => {},
  setDisableActionBtn = () => {},
}) => {
  const essayStore = useEssayStore();
  const steps = ['Select Essay', 'Copy or  Select Template'];
  const [selectedEssayFromPlatform, setSelectedEssayFromPlatform] = useState(
    {}
  );
  const [selectedCoreEssay, setSelectedCoreEssay] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [selectedDropdownValue, setSelectedDropdownValue] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const onChange = (event, newValue) => {
    setSelectedDropdownValue(newValue);
  };
  const [selectedApplicationPlatform, setSelectedApplicationPlatform] =
    useState({});

  const [checkAccess] = useAccessCheck([UTILITY_TYPES.Applications]);

  useEffect(() => {
    setSelectedApplicationPlatform(
      itemList.find((item) => item.id === selectedListItem)
    );
    //eslint-disable-next-line
  }, [selectedListItem]);

  // // To set the default team members
  // function defaultTeamMembers() {
  //   if (isAdding) {
  //     return collaborators;
  //   }
  //   return selectedCollaborators;
  // }

  const [selectedTeamMembers, setSelectedTeamMembers] = useState(() =>
    filterUniAndTeamUniversily(collaborators)
  );

  useEffect(() => {
    const essayForPlatformCopied =
      selectedEssayFromPlatform && selectedCoreEssay;
    setEssayData({
      selectedCoreEssayDocs: essayForPlatformCopied
        ? selectedCoreEssay?.docs
        : '',
      essayCategory:
        selectedCoreEssay?.essayCategory || selectTemplate?.tag || '',
      essaySubType:
        selectedRadio === essaySubTypeApplicationEssay.college &&
        selectedEssayFromPlatform?.essayType !== 'Common Essay'
          ? essaySubTypeApplicationEssay.college
          : essaySubTypeApplicationEssay.application,
      type: essayTypeForPlatform.applicationEssay,
      essayName:
        selectedRadio === essaySubTypeApplicationEssay.college
          ? selectedEssayFromPlatform?.essayType === 'Common Essay'
            ? `${
                applicationTags.find(
                  (tag) =>
                    tag.applicationPlatform ===
                    selectedEssayFromPlatform.whereSubmitted
                )?.label
              }-${selectedEssayFromPlatform?.essayName}`
            : `${selectedListItem[0]?.collegeData?.collegeName}-${selectedEssayFromPlatform?.essayName}`
          : `${selectedApplicationPlatform?.label}-${selectedEssayFromPlatform?.essayExternalName}`,
      applicationName:
        selectedRadio === essaySubTypeApplicationEssay.college &&
        selectedEssayFromPlatform?.essayType === 'Common Essay'
          ? selectedEssayFromPlatform?.whereSubmitted
          : selectedApplicationPlatform?.applicationPlatform,
      sharedWith: selectedTeamMembers,
      essayReferenceId:
        selectedEssayFromPlatform?.essayType === 'Common Essay'
          ? selectedEssayFromPlatform?.essayId
          : selectedEssayFromPlatform?.id,
      copiedFromId: essayForPlatformCopied ? selectedCoreEssay?.id : '',
      collegeId:
        selectedRadio === essaySubTypeApplicationEssay.college &&
        selectedEssayFromPlatform?.essayType !== 'Common Essay'
          ? selectedListItem[0]?.collegeId
          : '',
    });
    //eslint-disable-next-line
  }, [
    selectedEssayFromPlatform,
    selectedCoreEssay,
    selectTemplate,
    selectedTeamMembers,
  ]);

  useEffect(() => {
    if (copyOption === 'selectTemplate') {
      setSelectedCoreEssay({});
    } else {
      setSelectTemplate({});
    }
    //eslint-disable-next-line
  }, [copyOption]);


  useEffect(() => {
    let shouldDisable = true;
  
    if (activeStep === 1) {
      if (
        (selectedListItem && selectedListItem.length > 0) ||
        (essayList && essayList.length > 0 && Object.keys(selectedEssayFromPlatform).length > 0)
      ) {
        shouldDisable = false;
      }
    } else if (activeStep === 2) {
      if (
        (copyOption === 'coreEssay' && Object.keys(selectedCoreEssay).length > 0) ||
        (copyOption !== 'coreEssay' && Object.keys(selectTemplate).length > 0)
      ) {
        shouldDisable = false;
      }
    }
  
    setDisableActionBtn(shouldDisable);
  }, [activeStep, selectedListItem, essayList, selectedEssayFromPlatform, selectTemplate, selectedCoreEssay, copyOption]);
  

  return (
    <div className="w-full h-full flex flex-col gap-6">
      {!essayStore.isCopyingApplicationEssay && (
        <div className="w-6/12 flex flex-col gap-8">
          <Stepper activeStep={activeStep - 1}>
            {steps.map((step) => (
              <Step key={step}>
                <StepLabel
                  className="cursor-pointer"
                  onClick={() => {
                    if (step === 'Select Essay') setAddProcessStep(1);
                    else setAddProcessStep(2);
                  }}
                  sx={{
                    '& .Mui-completed': {
                      '& .MuiStepIcon-root': { color: '#30B28C' },
                    },
                  }}
                >
                  {step}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      )}
      {activeStep === 1 && (
        <div className="flex flex-col h-full gap-6">
          {selectedRadio === essaySubTypeApplicationEssay.college ? (
            <Tooltip
              title="Select which college application to which you want to copy this essay."
              placement="right"
            >
              <div className="w-6/12">
                <CollegeAutoComplete
                  value={selectedListItem}
                  options={itemList}
                  label={selectItemsLabel}
                  isAddNewButton={true}
                  onChange={(e, newValue) => {
                    if (newValue.length > 0) {
                      setSelectedListItem([newValue[newValue?.length - 1]]);
                    } else {
                      setSelectedListItem([]);
                    }
                  }}
                  onAddNew={() => {
                    if (checkAccess) {
                      essayStore.setIsAddCollege(true);
                    } else {
                      openSnackbar(
                        'You have reached the maximum limit, please upgrade to add more',
                        'error'
                      );
                    }
                  }}
                  onClose={() => setSelectedListItem([])}
                />
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              title="Select the Application Platform for which you want to write this essay."
              placement="right"
            >
              <div className="w-6/12">
                <SelectOption
                  label={selectItemsLabel}
                  options={itemList}
                  value={selectedListItem}
                  onChange={(event) => setSelectedListItem(event.target.value)}
                  isEssay={true}
                />
              </div>
            </Tooltip>
          )}

          <div className="flex flex-1 gap-4 text-sm h-96">
            <div className="flex flex-col gap-2">
              <div>Select Essay Name</div>
              <div className="flex flex-col border border-grey-500/23 rounded-lg h-80 w-72 overflow-y-scroll p-2">
                {essayList?.map((essay, index) => {
                  return (
                    <div
                      key={index}
                      className={`${
                        selectedEssayFromPlatform === essay &&
                        'bg-alert-info rounded-lg'
                      } flex gap-3 rounded-lg cursor-pointer p-4 hover:bg-cream-50 hover:rounded-lg`}
                      onClick={() => setSelectedEssayFromPlatform(essay)}
                    >
                      <IconButton>
                        <Radio
                          checked={selectedEssayFromPlatform === essay}
                          name="radio-buttons"
                          sx={{
                            padding: 0,
                          }}
                          onChange={() => setSelectedEssayFromPlatform(essay)}
                        />
                      </IconButton>

                      <div className="flex flex-col gap-1">
                        <div className="text-grey-500 word-break">
                          {essay.essayExternalName || essay?.essayName}
                        </div>
                        <div className="text-xs text-grey-300">
                          ({essay.wordLimit} words)
                        </div>
                        {checkEssayAdded(
                          essay?.essayType === 'Common Essay'
                            ? essay?.essayId
                            : essay?.id,
                          essayStore.essays
                        ) && (
                          <div className="text-green-400 text-xs flex items-center gap-2">
                            <CheckCircleIcon color="#47C257" fontSize="small" />
                            <div>Essay Added</div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-col flex-1 gap-2">
              <div>Prompt</div>
              <div className="flex flex-col border border-grey-500/23 text-sm text-[#595959] overflow-y-scroll p-6 rounded-lg h-80 whitespace-pre-wrap">
                {selectedEssayFromPlatform?.essayPrompt ||
                  selectedEssayFromPlatform?.prompt}
              </div>
            </div>
          </div>
          <SelectTeamMembers
            label="Select Team Member (optional)"
            multiple={true}
            stTitle="Select your Existing Team Members"
            tooltipTitle={`You can select or add Team Members with whom you wish to collaborate`}
            options={collaborators}
            value={selectedTeamMembers}
            onChange={(e, teamMembers) => {
              setSelectedTeamMembers(teamMembers);
            }}
          />
        </div>
      )}
      {activeStep === 2 && !essayStore.isCopyingApplicationEssay && (
        <div className="flex flex-col h-full gap-6">
          <div
            className={`${
              selectedTeamMembers ? 'p-3' : 'p-5'
            } bg-grey-50 flex gap-4 text-grey-500 text-sm rounded-lg items-center`}
          >
            <div>
              <div>
                {selectedRadio === essaySubTypeApplicationEssay.college
                  ? selectedListItem[0]?.collegeData?.collegeName
                  : selectedApplicationPlatform?.label}
              </div>
            </div>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                borderColor: '#D6D6D8',
              }}
              variant="middle"
            />
            <div>
              {selectedRadio !== essaySubTypeApplicationEssay.college
                ? selectedEssayFromPlatform?.essayExternalName
                : selectedEssayFromPlatform?.essayName}
            </div>
            {selectedTeamMembers && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    borderColor: '#D6D6D8',
                  }}
                  variant="middle"
                />
                <TeamMembersGroup
                  reviewers={selectedTeamMembers}
                  isEssay={true}
                />
              </>
            )}
          </div>
          <div className="flex flex-col gap-5">
            <div className="flex text-xs text-lead-500">
              Copy from a Core Essay, or Select a Template
            </div>
            <div className="flex font-medium items-center gap-8">
              <div className="flex items-center gap-3">
                <Radio
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'Copy from written Core Essays' }}
                  sx={{
                    padding: 0,
                  }}
                  checked={copyOption === essayTypeForPlatform.coreEssay}
                  onChange={() => setCopyOption(essayTypeForPlatform.coreEssay)}
                />
                <div>Copy from written Core Essays</div>
              </div>
              <div className="flex items-center gap-3">
                <Radio
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'Select a Template' }}
                  sx={{
                    padding: 0,
                  }}
                  checked={copyOption === 'selectTemplate'}
                  onChange={() => setCopyOption('selectTemplate')}
                />
                <div>Select a Template</div>
              </div>
            </div>
          </div>
          {copyOption === essayTypeForPlatform.coreEssay && (
            <div className="flex flex-1 gap-4 text-sm h-80">
              <div className="flex flex-col gap-2">
                <div>Select Essay Name</div>
                <div className="flex flex-col border border-grey-500/23 rounded-lg h-80 w-72 overflow-y-auto p-2">
                  {coreEssays?.map((essay) => {
                    return (
                      <div
                        className={`${
                          selectedCoreEssay === essay &&
                          'bg-alert-info rounded-lg'
                        } flex gap-3 rounded-lg cursor-pointer p-4 hover:bg-cream-50 hover:rounded-lg`}
                        onClick={() => setSelectedCoreEssay(essay)}
                      >
                        <IconButton disabled={!essay?.docInformation?.content}>
                          <Radio
                            checked={selectedCoreEssay === essay}
                            disabled={!essay?.docInformation?.content}
                            name="radio-buttons"
                            sx={{
                              padding: 0,
                            }}
                            onChange={() => setSelectedCoreEssay(essay)}
                          />
                        </IconButton>
                        <div className="flex flex-col gap-1">
                          <div className="text-grey-500 word-break">
                            {essay.essayName}
                          </div>
                          <div className="text-xs text-grey-300">
                            ({essay?.docInformation?.wordCount} words)
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-col flex-1 gap-2">
                <div>Essay Preview</div>
                <div className="flex flex-col border border-grey-500/23 text-sm text-[#595959] overflow-y-scroll p-6 rounded-lg h-80">
                  {selectedCoreEssay &&
                    selectedCoreEssay?.docInformation?.content}
                </div>
              </div>
            </div>
          )}
          {copyOption === 'selectTemplate' && (
            <div>
              <div className="grid grid-cols-3 gap-4">
                {essayCategory?.map((essayType) => (
                  <div
                    className={`${
                      selectTemplate === essayType
                        ? 'bg-brand-blue-500/10'
                        : 'border-lead-900/23'
                    } cursor-pointer border rounded-lg text-grey-800 font-medium flex bg-white p-6 hover:bg-brand-blue-500/10`}
                    onClick={() => {
                      setSelectTemplate(essayType);
                    }}
                  >
                    {essayType.name}
                  </div>
                ))}
                <Tooltip
                  title={`We recommend you to use the Core Essays as a 
                reference to copy or any of the templates to fast 
              track your Essay writing. However you may start 
                with a blank page as well.`}
                >
                  <div
                    className={`${
                      selectTemplate === 'blankTemplate'
                        ? 'bg-brand-blue-500/10'
                        : 'border-lead-900/23'
                    }
                  cursor-pointer border border-dashed rounded-lg  text-grey-800 font-medium flex bg-white p-6 hover:bg-brand-blue-500/10`}
                    onClick={() => {
                      setSelectTemplate('blankTemplate');
                    }}
                  >
                    <Add
                      sx={{
                        color: '#383838',
                        marginRight: '18px',
                      }}
                    />
                    Start from a Blank Page
                  </div>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ApplicationEssayStepper;
