import { InputField } from './InputField';
import { GoogleEmailAlert } from './GoogleEmailAlert/GoogleEmailAlert';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '../../hooks/useDebounce';
import { getStoryItem } from '../../firebase/services/getServices';
import useProfileStore from '../../stores/ProfileStore';
import { useShallow } from 'zustand/react/shallow';
import { InputAdornment } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import useTeamStore from '../../stores/TeamStore';
import { getProfileDataByEmail } from '../../firebase/services/user';
import { isEmpty } from 'lodash';
import { ROLE_OPTION } from '../../constants/onBoarding';


export default function AddStudent({
  setStudentData = () => {},
  studentData = {},
  isTeamMemberExist = false,
  setIsTeamMemberExist = () => {},
}) {
  const debouncedSearch = useDebounce({value: studentData?.email || '', delay: 400});
  const [errorMessage, setErrorMessage] = useState('');
  
  const {profile} = useProfileStore(useShallow((state) => ({
    profile: state.profile,
  })));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStudentData({
      ...studentData,
      [name]: value,
    });
  };

  const handleSearch = useCallback(async (searchEmail) => {
    // 1st check if profile exist with the email or not
    // if yes, check profile type
    // if profile type is parent then show error
    // if profile type is student then check if the student get the student team data and check if profile email is present in the team members or not

    if (profile?.email === searchEmail) {
      setIsTeamMemberExist(true);
      setErrorMessage('You cannot add yourself as a team member');
      return;
    } else {
      // get the profile data
      const profileData = await getProfileDataByEmail(searchEmail);

      if(!isEmpty(profileData)) {
        // check if profile type is parent
        if(profileData?.profileType !== ROLE_OPTION.STUDENT) {
          setIsTeamMemberExist(true);
          setErrorMessage('You entered a parent email. Please enter a student email');
          return;
        } else {
          // get the student team data
          const studentTeams = await getStoryItem('teams', [{
            property: 'studentEmail',
            operator: '==',
            value: searchEmail,
          }]);

          console.log('studentTeams', studentTeams);

          if(studentTeams?.length) {
            const team = studentTeams[0];
            const teamMembers = team?.teamMembers;
            const isTeamMemberExist = teamMembers.some((member) => member.email === profile?.email);
            if(isTeamMemberExist) {
              setIsTeamMemberExist(true);
              setErrorMessage('Student is already a team member');
              return;
            }
          }
        }
      }
    }
    

    setIsTeamMemberExist(false)
  }, []);

  useEffect(() => {
    if(debouncedSearch) {
      handleSearch(debouncedSearch);
    }
  }, [debouncedSearch, handleSearch]);

  useEffect(() => {
  }, [isTeamMemberExist])

  return (
    <div className="flex flex-col gap-4 sm:gap-8">
      <div className="text-[13px] text-lead-500">
        Add a new team by inviting a Student.
      </div>
      <div className="w-full flex flex-col gap-4 sm:flex-row">
        <form className="w-full">
          <div className="w-full flex flex-col gap-4">
            <InputField
              label="Student Name"
              required
              type="text"
              id="name"
              name="name"
              variant="outlined"
              background="#FCFCF9"
              value={studentData.name}
              onChange={handleChange}
            />
            <InputField
              error={isTeamMemberExist}
              label="Student Email"
              required
              type="email"
              id="email"
              name="email"
              variant="outlined"
              background="#FCFCF9"
              value={studentData.email}
              onChange={handleChange}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
              sx={{
                '& .MuiFormHelperText-root': {
                  marginLeft: '0px',
                },
              }}
              helperText={
                isTeamMemberExist ? (
                  <div className="text-[#D32F2F] text-xs mt-2 flex items-center gap-1">
                  <ErrorIcon fontSize="small" />
                    {errorMessage}
                </div>
                ) : (
                  ''
                )
              }
            />
          </div>
        </form>
      </div>
      <GoogleEmailAlert />
    </div>
  );
}
