import { Add, ExpandMore } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from '@mui/material';
import { useState } from 'react';
import useChatStore from '../../stores/ChatStore';
import Button from '../shared/Button';
import Tooltip from '../shared/Tooltip';
import ChatListItem from './ChatListItem';

const ChatListGroup = ({
  userId = '',
  title = '',
  type = '',
  itemList = [],
  teamMembers = [],
  onAdd = () => {},
  onChatClick = () => {},
  createDMChannel = () => {},
  chatDMs = [],
  onArchiveTopicMessage = () => {},
  markMessagesAsRead = () => {},
  markMessagesAsUnread = () => {},
  isSearching = false,
  isParent = false,
  alreadyExpanded = false,
  showViewAll = false,
}) => {
  const chatStore = useChatStore();
  const [expanded, setExpanded] = useState(alreadyExpanded);

  const renderEmptyState = () => {
    return (
      <div className="flex flex-col gap-8 py-4">
        <div className="flex flex-col py-4 p-2 h-full bg-[#FCFCF9] border-2 border-dashed border-[#E7E7E1] gap-2 justify-center items-center rounded-xl">
          <div className="text-xs text-grey-300">
            {type === 'groupChats'
              ? (isParent ? 'Start adding a Team to chat about.' : 'Start adding a Team member in team to chat with.')
              : !isParent
              ? 'Collaborate with a Team Member to use Direct Messages.'
              : 'Collaborate with a Student to use Direct Messages'}
          </div>
          <div>
            <Button
              className="w-fit flex gap-2 items-center fourth-step"
              variant="text"
              onClick={onAdd}
            >
              <AddCircleIcon color="primary" />
              <div className="text-[#1958CD] font-medium cursor-pointer">
                {type === 'groupChats'
                  ? (isParent ? 'Add Team' : 'Add Team Member')
                  : !isParent
                  ? 'Add Team Member'
                  : 'Invite a Student'}
              </div>
            </Button>
          </div>
        </div>
        {/*<div className="text-xs text-grey-300">
          {type === 'topic'
            ? 'Add a Topic to proceed'
            : 'Add Team Member to use Direct Messages'}
        </div>
        <Tooltip title={type === 'topic' ? 'Add Topic' : 'Add Team Member'}>
          <div className="w-fit">
            <Button
              className="w-fit flex gap-2 items-center fourth-step"
              variant="text"
            >
              <AddCircleIcon color="primary" />
              <div
                className="text-[#1958CD] font-medium cursor-pointer"
                onClick={onAdd}
              >
                {type === 'topic' ? 'Add Topic' : 'Add Team Member'}
              </div>
            </Button>
          </div>
        </Tooltip>*/}
      </div>
    );
  };

  return (
    <div className="w-full py-2 flex flex-col">
      <Accordion
        className="w-full"
        onClick={() => {
          if (itemList && itemList.length > 0) {
            setExpanded(!expanded);
          }
        }}
        expanded={expanded}
        sx={{
          '& .MuiAccordionSummary-root': {
            padding: '0px !important',
            minHeight: '48px !important',
            display: 'flex !important',
            flexDirection: 'row-reverse !important',
          },
          backgroundColor: 'transparent !important',
          border: 'none !important',
          margin: '0px !important',
          boxShadow: 'none !important',
          '& .MuiAccordionDetails-root': {
            margin: '0px !important',
            padding: '0px 0px !important',
          },
          '& .MuiAccordionSummary-content': {
            margin: '0px !important',
            padding: '0px 0px !important',
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <IconButton disabled={itemList.length === 0}>
              <ExpandMore className="text-grey-900" />
            </IconButton>
          }
        >
          <div className="flex items-center w-full gap-1">
            <div className="text-grey-900 pl-2 text-sm font-medium">
              {title}
            </div>
            {(itemList.length > 0 && type === 'directMessage') && (
              <div className="flex ml-auto">
                {type !== 'archivedTopic' &&
                type !== 'archivedMessage' &&
                !(type === 'directMessage' && isParent) ? (
                  <Tooltip
                    title={type === 'groupChats' ? 'Add Team' : 'Add Team Member'}
                  >
                    <div>
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          onAdd();
                        }}
                      >
                        <Add className="text-grey-400" fontSize="small" />
                      </IconButton>
                    </div>
                  </Tooltip>
                ) : (
                  <div className="h-[18px] w-[18px] m-2 text-white flex items-center justify-center bg-grey-200 text-[10px] rounded-2xl">
                    {itemList?.length}
                  </div>
                )}
              </div>
            )}
          </div>
        </AccordionSummary>
        {itemList.length > 0 && (
          <AccordionDetails>
            <div>
              {
                <div>
                  {itemList.map((messageItem) => (
                    <ChatListItem
                      key={messageItem?.id}
                      archivedBy={messageItem?.archivedBy}
                      onArchiveTopicMessage={onArchiveTopicMessage}
                      userId={userId}
                      teamMemberExists={messageItem?.teamMemberExists}
                      avatar={messageItem?.photoURL}
                      id={messageItem?.id}
                      teamId={messageItem?.utilityId}
                      onChatClick={onChatClick}
                      allParticipantsDetails={messageItem?.allParticipantsDetails}
                      channelExists={messageItem?.chatParticipants?.length > 0}
                      createDMChannel={createDMChannel}
                      reviewerId={messageItem?.uid}
                      unreadCount={messageItem?.unreadMessages}
                      type={type}
                      name={
                        messageItem?.isTopic
                          ? messageItem?.topicName
                          : `${messageItem?.firstName} ${messageItem?.lastName}`
                      }
                      isParent={isParent}
                      utilityType={
                        messageItem?.isTopic ? messageItem?.topicType : ''
                      }
                      markMessagesAsRead={markMessagesAsRead}
                      markMessagesAsUnread={markMessagesAsUnread}
                    />
                  ))}
                  {showViewAll && (
                    <Button
                      variant="text"
                      color="link"
                      onClick={(e) => {
                        e.stopPropagation();
                        chatStore.setIsFilterTopic(false);
                        chatStore.setUtilityId(null);
                      }}
                    >
                      View all topics
                    </Button>
                  )}
                </div>
              }
            </div>
          </AccordionDetails>
        )}
      </Accordion>
      <div>
        {itemList.length === 0 &&
          type !== 'archivedTopic' &&
          type !== 'archivedMessage' &&
          !isSearching &&
          renderEmptyState()}
      </div>
    </div>
  );
};

export default ChatListGroup;
