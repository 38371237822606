import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import './index.css';
import ErrorBoundary from './layouts/ErrorBoundary';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <ErrorBoundary>
        <App />
    </ErrorBoundary>
);
