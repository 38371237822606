import { useEffect, useState } from 'react';
import Dialog from '../shared/Dialog';
import useEssayStore from '../../stores/EssayStore';
import { checkEssayAdded } from '../../utils/helper';
import AddApplicationEssay from './AddApplicationEssay';

const AddApplicationEssayDialog = ({
  onContinue = () => {},
  onCancel = () => {},
  coreEssays = [],
  isAdding = false,
  collaborators = [],
  commonEssays = [],
  selectTemplate,
  setSelectTemplate = () => {},
  copyOption = 'coreEssay',
  setCopyOption = () => {},
  addProcessStep,
  setAddProcessStep = () => {},
  collegeList = [],
  setDisableActionBtn = () => {},
  ...props
}) => {
  const [selectedRadio, setSelectedRadio] = useState('');
  const essayStore = useEssayStore();
  const [essayData, setEssayData] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // set setDisableActionBtn to true if selectedRadio is empty
    setDisableActionBtn(!selectedRadio);
  }, [selectedRadio]);

  return (
    <Dialog
      continueText={
        essayStore.isCopyingApplicationEssay && addProcessStep === 1
          ? 'Make A Copy'
          : !addProcessStep || addProcessStep === 1
          ? 'Next'
          : 'Add'
      }
      isLoading={isLoading}
      cancelText={
        addProcessStep === 2 || addProcessStep === 1 ? 'Back' : 'Cancel'
      }
      onContinue={async () => {
        if (selectedRadio !== '') {
          !addProcessStep ? setAddProcessStep(1) : setAddProcessStep(2);
        }
        if (addProcessStep === 1 && !essayStore.isCopyingApplicationEssay) {
          setIsLoading(true);
          const essay = checkEssayAdded(
            essayData?.essayReferenceId,
            essayStore.essays
          );
          if (essay) {
            essayStore.setAddOrEditEssayDocsId(essay?.id);
            essayStore.setAddOrEditEssayDocs(true);
            essayStore.setIsAddingEssay(false);
            essayStore.setIsAddingCoreEssay(false);
            essayStore.setIsEditingEssay(false);
            setSelectedRadio('');
            setAddProcessStep(null);
            setEssayData({});
          }
          setIsLoading(false);
        }
        if (addProcessStep === 1 && essayStore.isCopyingApplicationEssay) {
          setIsLoading(true);
          await onContinue(essayData);
          setIsLoading(false);
          essayStore.setIsCopyingApplicationEssay(false);
          setSelectedRadio('');
          setAddProcessStep(null);
          setEssayData({});
        }

        if (addProcessStep === 2) {
          setIsLoading(true);
          await onContinue(essayData);
          setIsLoading(false);
          essayStore.setIsAddingEssay(false);
          essayStore.setIsAddingCoreEssay(false);
          essayStore.setIsEditingEssay(false);
          setSelectedRadio('');
          setAddProcessStep(null);
          setEssayData({});
        }
      }}
      onCancel={() => {
        if (addProcessStep === 2) {
          setAddProcessStep(addProcessStep - 1);
        } else if (addProcessStep === 1) {
          setAddProcessStep(null);
        } else {
          onCancel();
          setSelectedRadio('');
          setAddProcessStep(null);
        }
      }}
      {...props}
    >
      <AddApplicationEssay
        selectedRadio={selectedRadio}
        setSelectedRadio={setSelectedRadio}
        addProcessStep={addProcessStep}
        setAddProcessStep={setAddProcessStep}
        coreEssays={coreEssays}
        collaborators={collaborators}
        isAdding={isAdding}
        commonEssays={commonEssays}
        essayData={essayData}
        collegeList={collegeList}
        setEssayData={setEssayData}
        selectTemplate={selectTemplate}
        setSelectTemplate={setSelectTemplate}
        copyOption={copyOption}
        setCopyOption={setCopyOption}
        setDisableActionBtn={setDisableActionBtn}
      />
    </Dialog>
  );
};

export default AddApplicationEssayDialog;
