import { ReactComponent as FamilyIcon } from '../assets/svgs/family.svg';
import { ReactComponent as TeacherIcon } from '../assets/svgs/teacher.svg';
import { ReactComponent as SchoolIcon } from '../assets/svgs/school.svg';
import { ReactComponent as PrivateIcon } from '../assets/svgs/private.svg';
import { ReactComponent as StudentIcon } from '../assets/svgs/student.svg';

export const onBoardingDetails = {
  studentDetails: {
    name: '',
    email: '',
  },
  gradeDetails: {
    grade: 0,
    gradeName: '',
  },
  highSchoolDetails: {
    highSchoolName: '',
    highSchoolLocation: '',
  },
  passionDetails: null,
  aimingCollegeDetails: {
    skipped: false,
    collegeName: '',
    collegeId: '',
  },
  courseDetails: {
    skipped: false,
    step: 0,
    courses: [
      {
        courseName: '',
        courseYear: '',
      },
    ],
  },
  honorDetails: {
    skipped: false,
    step: 0,
    honors: [
      {
        honorName: '',
        honorYear: '',
      },
    ],
  },
  activityDetails: {
    skipped: false,
    step: 0,
    activities: [
      {
        activityName: '',
        activityYear: '',
      },
    ],
  },
};

export const courseDetails = {
  skipped: false,
  step: 0,
  courses: [
    {
      courseName: '',
      courseYear: '',
    },
  ],
};

export const honorDetails = {
  skipped: false,
  step: 0,
  honors: [
    {
      honorName: '',
      honorYear: '',
    },
  ],
};

export const activityDetails = {
  skipped: false,
  step: 0,
  activities: [
    {
      activityName: '',
      activityYear: '',
    },
  ],
};

export const studentDetails = {
  name: '',
  email: '',
};

export const gradeDetails = {
  grade: 0,
  gradeName: '',
};

export const highSchoolDetails = {
  skipped: false, 
  highSchoolName: '',
  highSchoolLocation: '',
};

export const aimingCollegeDetails = {
  skipped: false,
  collegeData: [],
};

export const OnBoardingSteps = {
  ROLE_SELECTION: 'ROLE_SELECTION',
  STUDENT_DETAILS: 'STUDENT_DETAILS',
  GRADE_DETAILS: 'GRADE_DETAILS',
  HIGH_SCHOOL_DETAILS: 'HIGH_SCHOOL_DETAILS',
  PASSION_DETAILS: 'PASSION_DETAILS',
  COURSE_DETAILS: 'COURSE_DETAILS',
  COURSE_DETAILS_SECTION: 'COURSE_DETAILS_SECTION',
  HONOR_DETAILS: 'HONOR_DETAILS',
  HONOR_DETAILS_SECTION: 'HONOR_DETAILS_SECTION',
  ACTIVITY_DETAILS: 'ACTIVITY_DETAILS',
  ACTIVITY_DETAILS_SECTION: 'ACTIVITY_DETAILS_SECTION',
  AIMING_COLLEGE_DETAILS: 'AIMING_COLLEGE_DETAILS',
};

export const passionGradeOptions = [
  { value: 9, label: '9th' },
  { value: 10, label: '10th' },
  { value: 11, label: '11th' },
  { value: 12, label: '12th' },
];

export const gradeOptionsForStudent = [
  {
    id: 12,
    gradeName: 'Grade 12',
    label: 'Senior',
    isSelected: false,
  },
  {
    id: 10,
    gradeName: 'Grade 10',
    label: 'Sophomore',
    isSelected: false,
  },
  {
    id: 11,
    gradeName: 'Grade 11',
    label: 'Junior',
    isSelected: false,
  },
  {
    id: 9,
    gradeName: 'Grade 9 and below',
    label: 'Freshman',
    isSelected: false,
  },
];

export const ROLE_OPTION = {
  STUDENT: 'Student',
  FAMILY_MEMBER: 'Family',
  SCHOOL_COUNSELOR: 'School Counselor',
  PRIVATE_COUNSELOR: 'Private Counselor',
  TEACHER_COACH: 'Teacher/Coach',
};

export const roleMenuData = [
  {
    id: ROLE_OPTION.STUDENT,
    label: 'Student',
    icon: <StudentIcon className="w-9 h-9" />,
    isSelected: false,
  },
  {
    id: ROLE_OPTION.FAMILY_MEMBER,
    label: 'Family Member',
    icon: <FamilyIcon className="w-9 h-9" />,
    isSelected: false,
  },
  {
    id: ROLE_OPTION.TEACHER_COACH,
    label: 'Teacher/Coach',
    icon: <TeacherIcon className="w-9 h-9" />,
    isSelected: false,
  },
  {
    id: ROLE_OPTION.SCHOOL_COUNSELOR,
    label: 'School Counselor',
    icon: <SchoolIcon className="w-9 h-9" />,
    isSelected: false,
  },
  {
    id: ROLE_OPTION.PRIVATE_COUNSELOR,
    label: 'Private Counselor',
    icon: <PrivateIcon className="w-9 h-9" />,
    isSelected: false,
  },
];
