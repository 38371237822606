import React, { useEffect, useState } from 'react';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { Button } from '@mui/material';


export default function ErrorBoundary({ children }) {
    const [error, setError] = useState(null);

    useEffect(() => {
        const errorHandler = (event) => {
            event.preventDefault();
            console.error(event.error);
            setError(event.error);
        };

        const rejectionHandler = (event) => {
            event.preventDefault();
            console.error(event.reason);
            setError(event.reason);
        };

        window.addEventListener('error', errorHandler);
        window.addEventListener('unhandledrejection', rejectionHandler);

        return () => {
            window.removeEventListener('error', errorHandler);
            window.removeEventListener('unhandledrejection', rejectionHandler);
        };
    }, []);

    const handleReset = () => {
        window.location.href = '/';
    };

    if (error) {
        return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
            <div className="w-max bg-white rounded-lg shadow-lg p-8 text-center">
                <div className="flex justify-center mb-6">
                    <ErrorOutlineRoundedIcon className="text-red-500" 
                        sx={{ fontSize: '4rem' }}
                    />
                </div>
                <h1 className="text-2xl font-bold text-gray-900 mb-4">
                    Oops! Something went wrong
                </h1>
                <div className=" mb-6 flex justify-center w-full">
                    <p className="text-sm bg-rose-50/50 rounded-xl text-rose-800 font-mono break-words w-max p-4 border border-rose-100">
                        {error.message || 'An unexpected error occurred'}
                    </p>
                </div>
                <p className="text-gray-600 mb-8">
                    Don't worry! Click below to go back to the home page.
                </p>
                <Button
                    onClick={handleReset}
                    variant="contained"
                >
                    <HomeRoundedIcon className="h-5 w-5 mr-2" />
                    Go Back To Home
                </Button>
            </div>
        </div>
        );
    }

    try {
        return <>{children}</>;
    } catch (err) {
        setError(err ? err : new Error('An unexpected error occurred'));
        return null;
    }
}